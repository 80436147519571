.card {
    margin-bottom: $cards-grid-gap;
    box-shadow: $card-shadow;
    @include media-breakpoint-down($cards-grid-breakpoint) {
        margin-bottom: $cards-grid-gap-sm;
    }
    @media print {
        border: none;
        box-shadow: none;
    }
    @at-root a#{&} {
        color: inherit;
        transition: .3s box-shadow;
        &:hover {
            text-decoration: none;
            box-shadow: $card-shadow-hover;
        }
    }
}

.card-group {
    margin-bottom: $card-group-margin;
}

.card-inactive {
    pointer-events: none;
    box-shadow: none;
    .card-body {
        opacity: .64;
    }
}

.card-active {
    position: relative;
    background-color: rgba($primary, .03);
    &:before {
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        content: "";
        border: 1px solid $primary;
        border-radius: inherit;
    }
}

.card-link {
    color: inherit;
    &:hover {
        color: inherit;
        text-decoration: none;
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .08);
    }
}

.card-btn {
    padding: $card-spacer-y $card-spacer-x;
    text-align: center;
    background: rgba($primary, .02);
    transition: .3s background;
    &:hover {
        text-decoration: none;
        background: $active-bg;
    }
}

.card-stacked {
    position: relative;
    &:after {
        position: absolute;
        top: -5px;
        right: 5px;
        left: 5px;
        height: 5px;
        content: "";
        background: $card-bg;
        border: 1px solid $card-border-color;
        border-radius: $card-border-radius $card-border-radius 0 0;
    }
}

.card-cover {
    position: relative;
    padding: $card-spacer-y $card-spacer-x;
    background: #666666 no-repeat center/cover;
    &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background: $light-black;
    }
    &:first-child,
    &:first-child:before {
        border-radius: $border-radius $border-radius 0 0;
    }
}

.card-cover-blurred {
    &:before {
        backdrop-filter: blur(2px);
    }
}

.card-actions {
    margin-left: auto;
    font-size: $h5-font-size;
    a {
        text-decoration: none;
    }
}

.card-header {
    display: flex;
    align-items: center;
    background: transparent;
}

.card-header-tabs {
    flex: 1;
    margin: (- $card-spacer-y) (- $card-spacer-x);
}

.card-header-pills {
    flex: 1;
}

.card-footer {
    color: $text-muted;
}

.card-progress {
    height: .25rem;
    &:last-child {
        border-radius: 0 0 2px 2px;
    }
    &:first-child {
        border-radius: 2px 2px 0 0;
    }
}

.card-meta {
    color: $text-muted;
}

.card-title {
    display: block;
    margin: 0 0 1rem;
    font-size: $card-title-font-size;
    font-weight: $headings-font-weight;
    color: $headings-color;
    line-height: 1.2rem;
    @at-root a#{&}:hover {
        color: inherit;
    }
    .card-header & {
        margin: .125rem 0;
    }
}

.card-subtitle {
    margin-top: -$card-spacer-y;
    margin-bottom: $card-spacer-y;
    font-size: $small-font-size;
    color: $text-muted;
}

.card-body {
    > :last-child {
        margin-bottom: 0;
    }
    .card-sm>& {
        padding: .75rem;
    }
    .card-md>& {
        @include media-breakpoint-up(md) {
            padding: 1.5rem;
        }
    }
    .card-lg>& {
        @include media-breakpoint-up(md) {
            padding: 2rem;
        }
        @include media-breakpoint-up(lg) {
            padding: 4rem;
        }
    }
    @media print {
        padding: 0;
    }
    &+& {
        border-top: 1px solid $border-color;
    }
}

.card-body-scrollable {
    overflow: auto;
}


/**
Card optinos
 */

.card-options {
    top: 1.5rem;
    right: .75rem;
    display: flex;
    margin-left: auto;
}

.card-options-link {
    display: inline-block;
    min-width: 1rem;
    margin-left: .25rem;
    color: $text-muted;
}

.card-drop {
    line-height: 1;
    color: $text-muted;
    .icon {
        width: 1.5rem;
        height: 1.5rem;
        stroke-width: 1;
    }
}


/**
Card status
 */

.card-status-top {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: $card-status-size;
    border-radius: $card-border-radius $card-border-radius 0 0;
}

.card-status-left {
    position: absolute;
    right: auto;
    bottom: 0;
    width: $card-status-size;
    height: 100%;
    border-radius: $card-border-radius 0 0 $card-border-radius;
}

.card-status-bottom {
    position: absolute;
    top: initial;
    bottom: 0;
    width: 100%;
    height: $card-status-size;
    border-radius: 0 0 $card-border-radius $card-border-radius;
}


/**
Card table
 */

.card-table {
    margin-bottom: 0;
    tr {
        .card-header~.table-responsive &,
        .card-header~& {
            &:first-child td {
                //border-top: 0;
            }
        }
        td,
        th {
            //border-top: 1px solid $border-color;
            &:first-child {
                padding-left: $card-spacer-x;
            }
            &:last-child {
                padding-right: $card-spacer-x;
            }
        }
    }
    thead {
        tr {
            &:first-child {
                td,
                th {
                    border-top: 0;
                }
            }
        }
    }
    tbody {
        tr:last-child {
            td,
            th {
                border-bottom: 0;
            }
        }
    }
    .card-body+& {
        border-top: 1px solid $border-color;
    }
}


/*
Card code
 */

.card-code {
    padding: 0;
    .highlight {
        margin: 0;
        border: 0;
    }
    pre {
        margin: 0;
        color: #ffffff;
        text-shadow: none;
        background: transparent;
    }
}


/*
Card chart
 */

.card-chart {
    position: relative;
    z-index: 1;
    height: 3.5rem;
}


/**
Card avatar
 */

.card-avatar {
    box-shadow: 0 0 0 .5rem $card-bg;
}


/*
Card list group
 */

.card-list-group {
    .card-body+& {
        border-top: 1px solid $border-color;
    }
    .list-group-item {
        padding-right: $card-spacer-x;
        padding-left: $card-spacer-x;
        border-right: 0;
        border-left: 0;
        border-radius: 0;
        &:last-child {
            border-bottom: 0;
        }
        &:first-child {
            border-top: 0;
        }
    }
}


/**
Card tabs
 */

.card-tabs {
    margin-bottom: $card-group-margin;
    .nav-tabs {
        position: relative;
        z-index: $zindex-dropdown;
        border-bottom: 0;
        .nav-link {
            background: $card-cap-bg;
            border: $card-border-width solid $card-border-color;
            border-bottom: 0;
            &.active,
            &:active,
            &:hover {
                border-color: $card-border-color;
            }
            &.active {
                color: $headings-color;
                background: $card-bg;
            }
        }
        .nav-item {
            &:not(:first-child) {
                .nav-link {
                    border-top-left-radius: 0;
                }
            }
            &:not(:last-child) {
                .nav-link {
                    border-top-right-radius: 0;
                }
            }
            +.nav-item {
                margin-left: -1px;
            }
        }
    }
    .nav-tabs-bottom {
        margin-bottom: 0;
        .nav-item {
            margin-top: -1px;
            margin-bottom: 0;
            .nav-link {
                border-top-width: 0;
                border-bottom: $card-border-width solid $card-border-color;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
    .card {
        margin: 0;
        border-top-left-radius: 0;
    }
}