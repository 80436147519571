.selectize-control {
    padding: 0;
    border: 0;
    height: auto;
    &.multi .selectize-input {
        &.has-items {
            padding-left: 6px;
        }
        >div {
            // font-size: $h5-font-size;
            font-size: inherit;
            margin: 0 6px 2px 0;
            padding: 2px 8px;
            border-radius: $border-radius;
            background-color: $min-white;
            color: inherit;
        }
    }
    .remove {
        font-size: inherit !important;
        font-weight: normal !important;
        border-left: 0 !important;
        color: $text-muted !important;
    }
    @each $state,
    $data in $form-validation-states {
        $color: map-get($data, 'color');
        &.is-#{$state} {
            padding: 0;
            .selectize-input {
                border-color: $color;
                &.focus {
                    box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                }
            }
            .selectize-dropdown {
                background: none;
                background-color: inherit;
                border-color: $border-color;
                padding-right: 0;
            }
        }
    }
}

.selectize-input {
    display: flex !important;
    border: 1px solid $border-color;
    padding: $input-padding-y $input-padding-x;
    font-size: $input-font-size;
    box-shadow: none;
    line-height: $input-line-height;
    background-color: inherit !important;
    color: inherit !important;
    flex-wrap: wrap;
    &.full {
        background-color: inherit;
    }
    &.dropdown-active {
        border-radius: $border-radius;
    }
    .selectize-control.single & {
        background: escape-svg($form-select-indicator) no-repeat $form-select-bg-position/$form-select-bg-size;
    }
    &.focus {
        border-color: $form-select-focus-border-color;
        box-shadow: $form-select-focus-box-shadow;
    }
    &:after {
        content: none !important;
    }
}

.selectize-dropdown {
    height: auto;
    background: $form-select-bg;
    padding: $input-padding-y 0;
    margin-top: 1px;
    .option,
    .option-header {
        padding: .25rem $input-padding-x;
    }
    .active {
        background: $hover-bg;
        color: inherit;
        cursor: pointer;
    }
}